import React from 'react'
import './ModalWindow.css'
import Header from '../Header/Header'

const ModalWindow = ({ children, isOpened, callModal}) => {
  return (
    <div className='modalWindow'>
      <Header isOpened={isOpened} callModal={callModal} />
      {children}
    </div>
  )
}

export default ModalWindow
