import React, { useEffect, useState } from 'react'
import ClientInput from '../../Login/EnterNameBlock/ClientInput'
import Tag from '../../Tags/Tag'
import TextArticleLink from './TextArticleLink';
import './Articles.css'
import VideoArticleLink from './VideoArticleLink';
import { useParams } from 'react-router-dom';
import '../../Tags/Tags.css'

const QuestionList = ({ questions }) => {
  const [filter, setFIlter] = useState('');
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [tag, setTag] = useState(undefined);
  const [articles, setArticles] = useState([]);
  const { tagId } = useParams();

  useEffect(() => {
    // console.log("Getting tag after tagId", tagId);
    if (tagId) {
      fetch(`/backend/api/tags/${tagId}?populate[0]=articles.Content.Cover`).then(response => response.json().then(data => {
        // console.log("This is tag for id", data);
        setTag({ text: data.data.attributes.Name, emoji: data.data.attributes.emoji });
        setArticles(data.data.attributes.articles.data);
      }))
    } else {
      fetch('backend/api/articles?pagination[start]=0&pagination[limit]=-1&populate[0]=Content.Cover&populate[1]=tags&filters[tags][Name][$null]=true').then(response => response.json().then(data => {
        // console.log("This is all articles", data);
        setArticles(data.data);
      }))
    }
  }, [questions])

  useEffect(() => {
    setFilteredArticles(articles.filter(article => article.attributes.Name.toLowerCase().includes(filter.toLowerCase())));
  }, [filter])

  return (
    <div style={{ height: "calc(100vh - 72px)", overflowY: 'scroll' }}>
      {tag && !questions ? <>
        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
          <Tag text={tag.text} emoji={tag.emoji} />
        </div>
        {/* <p className='AddTagDescription' style={{marginTop: 20}}>По данному тегу ты можешь ознакомиться со списокм ресурсов</p> */}
      </> : <>
        <div style={{ marginTop: 20 }}>
          <ClientInput placeholder={"Поиск вопроса"} iconClass={'input_search'} onChange={(e) => { setFIlter(e.target.value) }} />
        </div>
      </>
      }
      <div className='articlesHolder' style={{ marginTop: 20 }}>
        {articles && !filter ? articles.map((article, index) => {
          // console.log("This is article", article)
          switch (article.attributes.Content[0].__component) {
            case "article-content.content-text":
            case "article-content.content-rich-text":
              return <TextArticleLink articleName={article.attributes.Name} articleId={article.id} key={index} />
            case "article-content.content-video":
              return <VideoArticleLink articleName={article.attributes.Name} previewLink={article.attributes.Content[0].Cover.data?.attributes.url} videoLink={article.attributes.Content[0].VideoLink} key={index} />
            default:
              return ''
          }
        }) : filteredArticles.map(article => {
          // console.log("This is article", article);
          switch (article.attributes.Content[0].__component) {
            case "article-content.content-text":
            case "article-content.content-rich-text":
              return <TextArticleLink articleName={article.attributes.Name} articleId={article.id} />
            case "article-content.content-video":
              return <VideoArticleLink articleName={article.attributes.Name} previewLink={article.attributes.Content[0].Cover.data.attributes.url} />
            default:
              return ''
          }
        })}
        {/* <TextArticleLink articleName={"Как исполнить своё предназначение в жизни?"}/>
            <TextArticleLink articleName={"Не могу себя, заставить делать уроки"}/>
            <VideoArticleLink/>
            <VideoArticleLink/>
            <VideoArticleLink/>
            <TextArticleLink articleName={"Не могу себя, заставить делать уроки"}/>
            <VideoArticleLink/>
            <TextArticleLink articleName={"Не могу себя, заставить делать уроки"}/> */}
      </div>
    </div>
  )
}

export default QuestionList
