import React from 'react'
import ActivePoint from './Media/ActivePoint.svg'
import PassivePoint from './Media/PassivePoint.svg'

const RatePoint = ({active, rating, setRating}) => {
  return (
    <div onClick={() => setRating(rating)} style={{width: '31.7px', height: '30.15px', backgroundImage: `url(${active ? ActivePoint : PassivePoint})`, backgroundSize: 'contain'}}></div>
  )
}

export default RatePoint