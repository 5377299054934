import React, {useState, useEffect} from 'react'
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';
// import { TEST_TAGS } from './AddTagPage'
import CancelIcon from './Media/CancelIcon.svg'
import TagArticleLink from './TagAritcleLink';
import TagHolder from './TagHolder';
import './Tags.css'

const FindArticlesByTag = () => {

    const navigate = useNavigate();

    const [filter, setFilter] = useState('');
    const [filteredTags, setFilteredTags] = useState([])
    const [showAllTags, setShowAllTags] = useState(false);
    const [TEST_TAGS, setTEST_TAGS] = useState([])

    useEffect(() => {
        fetch('/backend/api/tags?populate[1]=articles').then(response => response.json().then(data => {
            // console.log("THIS IS TAGS ARRAY", data.data)
            const tags = data.data.map(tag => {return {id: tag.id, text: tag.attributes.Name, emoji: tag.attributes.emoji, articlesCount: tag.attributes.articles.data.length }})
            setTEST_TAGS(tags);
        }))
    },[])

    useEffect(() => {
        if (showAllTags) setShowAllTags(false);
        if (filter) {
            setFilteredTags(TEST_TAGS.filter(tag => tag.text.toLowerCase().includes(filter.toLowerCase())));
        } else {
            setFilteredTags(TEST_TAGS);
        }
    }, [filter])

    const getMostPopularTags = (tags, count) => {
        var mostPopularTags = [];
        for (let i = 0; i<tags.length; i++){
            if (mostPopularTags.length < count){
                mostPopularTags.push(tags[i]);
            } else {
                for (let l = 0; l < mostPopularTags.length; l++){
                    if (mostPopularTags[l].articlesCount < tags[i].articlesCount){
                        // console.log("Count of article in tag more than in mostPopular", mostPopularTags[l], tags[i])
                        mostPopularTags.push(tags[i]);
                        // console.log("New most popular entrance", mostPopularTags)
                        if (mostPopularTags.length > count){
                            mostPopularTags = [...removeListPopular(mostPopularTags)];
                            // console.log("After removing some less popular stuff", mostPopularTags);
                        }
                        break;
                    }
                }
            }
        }
        return mostPopularTags;
    }

    const removeListPopular = (tags) => {
        if (tags.length){
            var minIndex = 0
            var minValue = tags[0];
            for(let i = 0; i < tags.length; i++){
                if (tags[i].articlesCount < minValue.articlesCount){
                    minValue = tags[i];
                    minIndex = i;
                }
            }

            // console.log("TEST Remove less popular tag from ", tags);
            // console.log("TEST Less popular tag is", minValue, minIndex);

            var result = [...tags];
            result.splice(minIndex, 1);
            return result;
        } else {
            return tags;
        }
    }

    return (
    <div>
        <div className='tags_find' style={{marginTop: 20}}>
            <input type="text" name="" id="" placeholder='Начни вводить тег' value={filter} onChange={(e) => setFilter(e.target.value)}/>
            <img src={CancelIcon} alt="" onClick={() => setFilter('')}/>
        </div>
        { !showAllTags ? <>
                <p className='AddTagDescription' style={{marginTop: 20}}>{filter ? "Результаты поиска: " : "Популярные теги: "}</p>
                <div className='Tags-Articles__holder' style={{marginTop: 16, marginBottom: 16}}>
                    { !filter ? getMostPopularTags(TEST_TAGS, 4).map((tag, index) => <TagArticleLink tag={tag} articleNumber={tag.articlesCount} key={index}/>) : getMostPopularTags(filteredTags, 4).map((tag, index) => <TagArticleLink tag={tag} articleNumber={tag.articlesCount} key={index}/>) }
                </div>
                <Button buttonText={"Посмотреть все теги"} onClickAction={() => {console.log("Show all tags"); setShowAllTags(true)}} />
            </> : <>
                <p className='AddTagDescription' style={{marginTop: 20}}>Все теги: </p>
                <div className='tags_holder' style={{marginTop: 20}}>
                    <TagHolder tags={TEST_TAGS} addChosenTag={(tag) => {navigate(`/tags/${tag.id}`)}}/>
                </div>
            </>
        }
    </div>
    )
}

export default FindArticlesByTag