import React from 'react'
import TestImage from './Media/test.jpg'
import PlayButton from './Media/PlayButton.svg'

const VideoPreview = ({previewLink}) => {
  return (
    <div className='video__previewContainer' style={{backgroundImage: `url(/backend${previewLink})`}}>
        <img src={PlayButton} alt="" />
    </div>
  )
}

export default VideoPreview