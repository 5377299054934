import React from 'react'
import Arrow from './Media/ArticleArrow.svg'
import {useNavigate} from 'react-router-dom'

const TextArticleLink = ({articleName, articleId}) => {
  const navigate = useNavigate();

  return (
    <div className='articleLink' onClick={() => {navigate(`/article/${articleId}`)}}>
        <span>{articleName}</span>
        <img src={Arrow} alt="" />
    </div>
  )
}

export default TextArticleLink