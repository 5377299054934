import React, { useEffect, useState } from 'react'
import ClientInput from '../Login/EnterNameBlock/ClientInput'
import Tag from './Tag'
import TagHolder from './TagHolder'
import './Tags.css'
import CancelIcon from './Media/CancelIcon.svg'
import Button from '../Button/Button'

// export const TEST_TAGS= [
//     {
//         emoji: '👨‍👩‍👦',
//         text: 'Семья',
//         articlesCount: 16,
//     },
//     {
//         emoji: '🧑‍🏫',
//         text: 'Экзамены',
//         articlesCount: 1,
//     },
//     {
//         emoji: '👨‍👩‍👦',
//         text: 'Дружба',
//         articlesCount: 2,
//     },
//     {
//         emoji: '🧑‍🏫',
//         text: 'Неудача',
//         articlesCount: 5,
//     },
//     {
//         emoji: '👨‍👩‍👦',
//         text: 'Самопрезенткция',
//         articlesCount: 10,
//     },
//     {
//         emoji: '🧑‍🏫',
//         text: 'Кнофликт',
//         articlesCount: 13,
//     },
//     {
//         emoji: '👨‍👩‍👦',
//         text: 'Наркотики',
//         articlesCount: 8,
//     },
//     {
//         emoji: '🧑‍🏫',
//         text: 'Целепологание',
//         articlesCount: 9,
//     },
//     {
//         emoji: '👨‍👩‍👦',
//         text: 'Семья',
//         articlesCount: 7,
//     },
//     {
//         emoji: '🧑‍🏫',
//         text: 'Экзамены',
//         articlesCount: 6,
//     },
//     {
//         emoji: '👨‍👩‍👦',
//         text: 'Семья',
//         articlesCount: 5,
//     },
//     {
//         emoji: '🧑‍🏫',
//         text: 'Экзамены',
//         articlesCount: 4,
//     },
//     {
//         emoji: '👨‍👩‍👦',
//         text: 'Семья',
//         articlesCount: 3,
//     },
//     {
//         emoji: '🧑‍🏫',
//         text: 'Экзамены',
//         articlesCount: 2,
//     },
//     {
//         emoji: '👨‍👩‍👦',
//         text: 'Семья',
//         articlesCount: 1,
//     },
//     {
//         emoji: '🧑‍🏫',
//         text: 'Экзамены',
//         articlesCount: 0,
//     },
// ]

const AddTagPage = ({setTags, readyClick}) => {

    const [filter, setFilter] = useState('');
    const [filteredTags, setFilteredTags] = useState([])
    const [chosenTags, setChosenTags] = useState([]);
    const [TAGS, setTAGS] = useState([])

    useEffect(() => {
        fetch('/backend/api/tags?populate[1]=articles').then(response => response.json().then(data => {
            // console.log("THIS IS TAGS ARRAY", data.data)
            const tags = data.data.map(tag => {return {id: tag.id, text: tag.attributes.Name, emoji: tag.attributes.emoji, articlesCount: tag.attributes.articles.data.length }})
            setTAGS(tags);
        }))
    },[])

    useEffect(() => {
        // console.log("CREATE QUESTION this is chosen tags", chosenTags);
        // console.log("CREATE QUESTION this is array of chosenTags id", chosenTags.map(tag => tag.id));
        const newTagsIds = chosenTags.map(tag => tag.id)
        setTags(newTagsIds);
    }, [chosenTags])

    const addChosenTag = (tag) => {
        if (chosenTags.length < 3){
            setChosenTags([...chosenTags, tag]);
        }
    }

    const removeChosenTag = (tag) => {
        const newChosenArray = [...chosenTags.filter(ch_tag => ch_tag.text != tag.text)];
        setChosenTags(newChosenArray);
    }

    useEffect(() => {
        if (filter) {
            setFilteredTags(TAGS.filter(tag => tag.text.toLowerCase().includes(filter.toLowerCase())));
        } else {
            setFilteredTags(TAGS);
        }
    }, [filter, TAGS])

    // useEffect(() => {
    //     console.log("NEW TAGS AFTER FILTER", filteredTags);
    // }, [filteredTags])

    return (
    <div>
        {/* <ClientInput inputHeight={'48px'}/> */}
        <p className='AddTagDescription' style={{marginTop: 20}}>Ты можешь самостоятельно привязать  свой вопрос к тегам, выбери несколько  наиболее подходящих</p>
        <div className='tags_find' style={{marginTop: 20}}>
            <input type="text" name="" id="" placeholder='Начни вводить тег' value={filter} onChange={(e) => setFilter(e.target.value)}/>
            <img src={CancelIcon} alt="" onClick={() => setFilter('')}/>
        </div>
        <div className='chosenTags' style={{marginTop: 8}}>
            {chosenTags.map((tag, index) => <Tag emoji={tag.emoji} text={tag.text} key={index} onTagClick={() => removeChosenTag(tag)}/>)}
        </div>
        <p className='AddTagDescription' style={{marginTop: 20}}>Все теги:</p>
        <div className='tags_holder' style={{marginTop: 20}}>
            {/* {TEST_TAGS.map((tag, index) => <Tag emoji={tag.emoji} text={tag.text} key={index}/>)} */}
            <TagHolder tags={filteredTags} addChosenTag={addChosenTag}/>
        </div>
        <Button buttonText={'Готово'} onClickAction={readyClick}/>
    </div>
    )
}

export default AddTagPage