import React from 'react'
import MenuButton from '../../Header/MenuButton'
import './RateScreen.css'
import QuestionIcon from '../../Header/Media/QuestionIcon.svg'
import RatePlugin from './RatePlugin'
import Button from '../../Button/Button'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'

const RateScreen = ({satisfied}) => {
    const navigate= useNavigate();
    const {id} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const updateSiteRating = (rating) => {
        fetch(`/backend/api/qas/x?filter[UniqID]=${id}`, {method: 'PUT', body: JSON.stringify({data: {'SiteRating': rating}}), headers: {'Content-Type': 'application/json'}})
        navigate('/');
    }

    return (
        <div className='rateScreen'>
            {searchParams.get("rating") > 2 ? <>
                    <p>Если будут ещё вопросы  - приходи!</p>
                    <p>Ты можешь в любой момент зайти на сайт и найти информацию по самым разным темам, или задать ещё вопрос.</p>
                    <div style={{marginTop: '39px'}}>
                        <MenuButton text={"Перейти к вопросам"} icon={QuestionIcon}/>
                    </div>
                    <RatePlugin rateText={'Тебе понравился сайт?'} marginTop={'39px'} setRating={updateSiteRating} cancelAction={() => navigate('/')}/>
                </> : <>
                    <p>Попробуй по другому сформулироватьи написать вопрос, или выберииз списка — близкий вопрос по теме.</p>
                    <div style={{marginTop: '16px'}}>
                        <MenuButton text={"Перейти к вопросам"} icon={QuestionIcon}/>
                    </div>
                    <div style={{position: 'fixed', bottom: 24, width: 'calc(100% - 40px)'}}>
                        <Button buttonText={'Выход'} onClickAction={() => navigate('/')}/>
                    </div>
                </>
            }
        </div>
  )
}

export default RateScreen