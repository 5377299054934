import React, { useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import Tag from './Tag';

const article_grammar = ["статей", "статья", "статьи"]

export const getCorrectArticleGrammar = (number) => {
    const lastDigit = getLastDigit(number);
    switch (lastDigit){
        case 1:
            return article_grammar[1];
        case 2:
        case 3:
        case 4:
            return article_grammar[2];
        default:
            return article_grammar[0];
    }
}

export const getLastDigit = (number) => {
    return Number(String(number).slice(-1));
}

const TagArticleLink = ({tag, articleNumber}) => {
    const navigate = useNavigate();

    const [correctArticleGrammar, setCorrectArticleGrammar] = useState(getCorrectArticleGrammar(articleNumber));
    useEffect(() => {
        setCorrectArticleGrammar(getCorrectArticleGrammar(articleNumber));
    }, [articleNumber])

    return (
        <div onClick={() => navigate(`/tags/${tag.id}`)} className='TagArticlesLink'><Tag emoji={tag.emoji} text={tag.text}/><span>{articleNumber} {correctArticleGrammar}</span></div>
    )
}

export default TagArticleLink