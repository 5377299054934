import React from 'react'
import './EmergingContacts.css'
import EmergingContactsBlock from './EmergingContactsBlock'
import Button from '../../Button/Button'

const EmergingContacts = ({callModal}) => {
  return (
    <>
      <div className='emergingContacts__text'>
        <p>Если у тебя сложности и ты не&nbsp;знаешь что делать &mdash; </p>
        <p>позвони нам:</p>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', rowGap: '20px'}}>
        <EmergingContactsBlock name={`«Кризисный центр»`} tel={`8 (351) 735 02 18`} />
        <EmergingContactsBlock name={`Центр социальной защиты «СЕМЬЯ»`} tel={`8 (351) 700-15-51`} />
        <EmergingContactsBlock name={`Детский телефон доверия`} tel={`8 (800) 200 01 22`} />
        <EmergingContactsBlock name={`Центр мониторинга  социальных сетей`} tel={`8 (351) 222 07 56`} />
        <EmergingContactsBlock name={`«Областной центр диагностики и консультирования»`} tel={`8 (351) 232 00 57`} />
      </div>
      {/* <Button backgroundColor={'#51C0E2'} buttonText={'Закрыть'} onClickAction={()=> callModal(undefined)} /> */}
    </>
  )
}

export default EmergingContacts
