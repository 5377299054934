import React from 'react'
import VideoPreview from './VideoPreview'
// import { Link, useNavigate } from 'react-router-dom'

const VideoArticleLink = ({articleName, previewLink, videoLink}) => {
    // const navigate = useNavigate();
    return (
        <div className='videoArticleLink' onClick={() => {if (videoLink) window.open(videoLink, "_blank")}}>
            <VideoPreview previewLink={previewLink}/>
            <span>{articleName}</span>
        </div>
    )
}

export default VideoArticleLink