import React, { useEffect, useRef, useState } from 'react'
import Tag from './Tag';

export function BalancedSliceArray(array, sliceValue){
    // console.log("Trying to slice array on several parts: ", sliceValue)
    // console.log("ENTER POINT 5");
    if (sliceValue < 2){
        // console.log("ENTER POINT 6");
        return array;
    } else if (sliceValue <= 0){
        return null;
    }
    var len = array.length, out = [], i = 0, size;
    // console.log("ENTER POINT 4");

    // if (len % sliceValue === 0){
    //     size = Math.floor(len / sliceValue);
    //     // console.log("ENTER POINT 0");
    //     while (i < sliceValue){
    //         // console.log("ENTER POINT 1");
    //         out.push(array.slice(i, i + size));
    //         // console.log("Now out array is", out);
    //         i++;
    //     }
    // } else {
    // console.log("ENTER POINT 2");
        // console.log("This array cannot separate equally", array)
    while (i < array.length){
        // console.log("ENTER POINT 3");
        size = Math.ceil((len - i) / sliceValue--);
        // console.log("OUTPUT POINT 3", sliceValue, i, size);
        out.push(array.slice(i, i += size));
    }
    // }
    return out;
}

const TagHolder = ({tags, addChosenTag}) => {
    const [rowCount, setRowCount] = useState(0);
    const ref = useRef();

    useEffect(() => {
        const height = ref.current.clientHeight;
        // console.log("Height of tags holder", height);
        // console.log("Count of rows for this height", Math.floor(height / 48))
        setRowCount(Math.floor(height / 48));
    }, [tags])

    const getTagRow = (tags, index) => {
        return <div key={index} className='tagRow'>{tags.map((tag, index) => <Tag emoji={tag.emoji} text={tag.text} key={index} onTagClick={() => addChosenTag(tag)}/>)}</div>
    }

    const getTagTable = (tags, rowCount) => {
        const tags_Array =  BalancedSliceArray(tags, rowCount);
        // console.log("This is output array separated on parts", tags_Array);
        return tags_Array.map((tagsRow, index) => getTagRow(tagsRow, index))
    }

  return (
    <div style={{height: '100%'}} ref={ref} className="tagsTable">
        {rowCount && getTagTable(tags, rowCount)}
    </div>
  )
}

export default TagHolder