import React from 'react'
import MenuButton from './MenuButton'

import HeartIcon from './Media/HeartIcon.svg'
import TagIcon from './Media/TagIcon.svg'
// import ConsultIcon from './Media/ConsIcon.svg'
import ContactIcon from './Media/ContactIcon.svg'


const buttons = [
    {
        icon: HeartIcon,
        text: 'Популярные вопросы',
        link: '/questions'
    },
    {
        icon: TagIcon,
        text: 'Все теги',
        link: '/tags'
    },
    // {
    //     icon: ConsultIcon,
    //     text: 'Получить личную консультацию',
    //     link: '/consult'
    // },
    {
        icon: ContactIcon,
        text: 'Телефоны кризисных центров',
        link: '/contacts'
    }
]

const Menu = ({callModal}) => {

  return (
    <div className='menu'>
        {buttons.map((button, index) => <MenuButton callModal={callModal} key={index} icon={button.icon} link={button.link} text={button.text}/>)}
    </div>
  )
}

export default Menu