import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../Utils/Api';
import './App.css';
import Login from '../Login/Login'
import Header from '../Header/Header';
import QuestionForm from '../QuestionForm/QuestionForm'
import PersonalCode from '../PersonalCode/PersonalCode'
import ModalWindow from '../Modals/ModalWindow'
import Answer from '../Answer/Answer'
import WelcomeScreen from '../Login/WelcomeScreen/WelcomeScreen';
import EmergingContacts from '../Modals/EmergingContacts/EmergingContacts';
import QuestionList from '../Screens/QuestionsList/QuestionList';
import FindArticlesByTag from '../Tags/FindArticlesByTag';
import ConnectionForm from '../Screens/ConnectionForm/ConnectionForm';
import RateScreen from '../Screens/RateScreen/RateScreen';
import {CookiesProvider} from 'react-cookie';

function App() {
  const [modalElement, setModalElement] = useState(undefined)
  const [isWelcomeScreenOpen, setIsWelcomeScreenOpen] = useState(true)
  const [currentUser, setCurrentUser] = useState({ UserName: '' })
  const [errorMsg, setErrorMsg] = useState(undefined)
  const navigate = useNavigate();
  const [isOpened, setIsOpened] = useState(true)

  const createUser = (user) => {
    api.createUser(user)
      .then((res) => {
        if (res) {
          setCurrentUser(res.data.attributes)
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const getAnswer = (uniqID) => {
    api.getAnswer(uniqID)
      .then((res) => {
        if (res) {
          setCurrentUser(res.data.attributes)
          setErrorMsg(undefined)
          navigate('/answer', { replace: true })
        }
      })
      .catch((e) => {
        console.error(e);
        if (e === '404' || e === '403'){
          setErrorMsg('Введен неверный код')
        }
        // navigate('/', { replace: true })
      });
  };

  const callModal = (element) => {
    setModalElement(element)
  }

  return (
    <CookiesProvider>
    <div className="App">
      <Header callModal={callModal} modalElement={modalElement}
      />
      <Routes>
        <Route path='/'
          element={
            <Login setCurrentUser={setCurrentUser}
              getAnswer={getAnswer}
              currentUser={currentUser}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg} />} />
        <Route path='/ask'
          element={
            <QuestionForm setCurrentUser={setCurrentUser}
              currentUser={currentUser}
              createUser={createUser}
            />} />
        <Route path='/getCode'
          element={
            <PersonalCode personalCode={currentUser.UniqID} />} />
        <Route path='/answer'
          element={
            <Answer question={currentUser.Question}
              answer={currentUser.Answer} user={currentUser}/>} />
        <Route path='/contacts'
          element={<EmergingContacts/>} />
        {/* <Route path='/consult'
          // element={<RateScreen satisfied={false}/>}
          element={<ConnectionForm/>}
        /> */}
        <Route path='/questions'
          // element={<RateScreen satisfied={false}/>}
          element={<QuestionList questions/>}
        />
        <Route path='/tags'
          // element={<RateScreen satisfied={false}/>}
          element={<FindArticlesByTag/>}
        />
        <Route path='/tags/:tagId'
          // element={<RateScreen satisfied={false}/>}
          element={<QuestionList/>}
        />
        <Route path='/article/:id'
          element={<Answer/>}
        />
        <Route path='/rate/:id'
          element={<RateScreen/>}
        />
      </Routes>
      {modalElement !== undefined && <ModalWindow callModal={()=>callModal()} isOpened={isOpened}>{modalElement}</ModalWindow>}
      {isWelcomeScreenOpen && <WelcomeScreen onClose={() => setIsWelcomeScreenOpen()} />}
    </div>
    </CookiesProvider>
  );
}

export default App;
