import React from 'react'
import { Link } from 'react-router-dom';
import './Button.css'


const Button = ({ buttonText, linkTo, onClickAction, backgroundColor }) => {

  return (
    <>
      {linkTo ? <Link to={linkTo} className="">
        <div className='button' style={{ backgroundColor: backgroundColor, }} onClick={() => onClickAction()}>{buttonText}</div>
      </Link>
        :
        <div className={`button`} onClick={onClickAction} style={{ backgroundColor: backgroundColor, }}>{buttonText}</div>}
    </>
  )
}
export default Button
