import React from 'react'
import './EmergingContacts.css'
import PhoneIcon from './Media/PhoneIcon.svg'

const EmergingContactsBlock = ({ name, tel }) => {
  return (
    <>
      <div className='emergingContacts__block_wi'>
        <img src={PhoneIcon} alt="" />
        <div className='emergingContacts__block'>
          <div className='emergingContacts__name'>{name}</div>
          <a href={`tel: ${tel}`} className="emergingContacts__phone">
            {tel}
          </a>
        </div>
      </div>
    </>
  )
}

export default EmergingContactsBlock
