import React, { useState } from 'react'
import './ConnectionFrom.css'
import {useNavigate} from 'react-router-dom'

import ArrowDown from './Media/SelectForm_Arrow_down.svg'
import ArrowUp from './Media/SelectForm_Arrow_up.svg'

import PhoneIcon from './Media/phoneIcon.svg'
import SMSIcon from './Media/SMSIcon.svg'
import EmailIcon from './Media/EmailIcon.svg'

import Check from  './Media/SelectedOption.svg'
import Button from '../../Button/Button'

import DataWorkShield from './Media/LinkFlag.svg'
import { useCookies } from 'react-cookie'

export function ValidateEmail(mail) 
{
 return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
}

const ConnectionForm = () => {
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(undefined);
    const [verificationChecked, setVerificationChecked] = useState(false);
    const [FeedbackValue, setFeedbackValue] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies(['school'])

    const navigate = useNavigate();

    const ValidateFeedbackValue = () => {
        if (selectedOption == 2){
            return ValidateEmail(FeedbackValue);
        } else {
            return FeedbackValue != '';
        }
    }

    const getTypeString = (typeInt) => {
        switch(typeInt){
            case 0:
                return 'phone';
            case 1:
                return 'sms';
            case 2:
                return 'email';
        }
    }

    const sendFeedbackForm = (type, value) => {
        
        fetch('/backend/api/consultations', {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "data": {
                    "Value": value,
                    "Type": getTypeString(type),
                    "school": cookies.school
                }
            })
    }).then(response => {
            // console.log(response);
            if (response.status == 200){
                navigate('/');
            }
        })
    }

    const optionsList = [
        {icon: PhoneIcon, text: "Позвоните мне"},
        {icon: SMSIcon, text: "Напишите смс"},
        {icon: EmailIcon, text: "Напишите на e-mail"},
    ];

    const toggleOptions = () => {
        setIsOptionsOpen(!isOptionsOpen);
      };
    return (
    <div className='connectionForm'>
        <p style={{marginTop: 20}}>Заполни любую форму ниже,<br/>и с тобой свяжутся</p>
        <p style={{marginTop: 20}}>Свяжитесь со мной</p>
        {/* <select name="connection_type" id="" defaultValue={'default'}>
            <option value="default" disabled hidden>Выберите способ</option>
            <option value="phone_call">Позвоните мне</option>
            <option value="phone_sms">Напишите смс</option>
            <option value="email">Напишите на e-mail</option>
        </select> */}
        <div style={{marginTop: 8}} onClick={toggleOptions} className="select_placeholder">
            {selectedOption === undefined ? <span className='select_placeholder_body'>Выбрать способ</span> : <div className="option inPlaceholder"><img src={optionsList[selectedOption].icon} alt="" /><span>{optionsList[selectedOption].text}</span></div>}
            <img src={isOptionsOpen ? ArrowUp : ArrowDown} alt="" />
            <div style={{marginTop: 8}} className={`options ${isOptionsOpen ? "show" : ""}`} >
                {optionsList.map((option, index) => (
                    <div key={index} className="option" onClick={() => setSelectedOption(index)}>
                        <img src={option.icon} alt="" />
                        <span>{option.text}</span>
                        {index == selectedOption && <img src={Check} alt="" />} 
                    </div>
                ))}
            </div>
        </div>
        {selectedOption !== undefined && <>
                <div style={{marginTop: 20}} className='phoneEmailInput'>
                    {selectedOption == 0 || selectedOption == 1 ?<><span className='phoneEmailInput_prefix'>+7</span>
                    <input placeholder='Номер телефна' type="text" name="" id="" value={FeedbackValue} onChange={(e) => setFeedbackValue(e.target.value)} /></> : <><span className='phoneEmailInput_prefix'>@</span>
                    <input type="email" name="" id="" placeholder='E-mail' value={FeedbackValue} onChange={(e) => setFeedbackValue(e.target.value)} /></>}
                </div>
                <label style={{marginTop: 20}} className='personalDataUsage' onChange={(e) => setVerificationChecked(e.target.checked)}> 
                    <input type="checkbox" name="" id=""/>
                    <span>Подтверждаю согласие на обработку персональных данных</span>
                </label>
                <div className='DataWork' style={{marginTop: 22}}>
                    <img src={DataWorkShield} alt="" />
                    <a href="https://docs.cntd.ru/document/901990046">152-ФЗ «О персональных данных»</a>
                </div>
                {verificationChecked && ValidateFeedbackValue() && <div className='ButtonHolder'>
                    <Button buttonText={'Свяжитесь со мной'} onClickAction={() => sendFeedbackForm(selectedOption, FeedbackValue)}/>
                </div>}
            </>
        }
    </div>
    )
}

export default ConnectionForm