import React from 'react'
import MenuArrow from './Media/MenuArrow.svg'
import { useNavigate } from 'react-router-dom'

const MenuButton = ({callModal, icon, text, link}) => {
    const navigate = useNavigate();
  return (
    <div className='menu_button' onClick={() => { if (link) navigate(link); callModal(undefined)}}>
        <img src={icon} alt="" />
        <span style={{flex: 'auto'}}>{text}</span>
        <img src={MenuArrow} alt="" />
    </div>
  )
}

export default MenuButton