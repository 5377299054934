import React, { useState } from 'react'
import RatePoint from './RatePoint'


const RatePlugin = ({rateText, marginTop, setRating, cancelAction}) => {
    const [currentRating, setCurrentRating] = useState(0)
    // console.log(Array.apply(null, Array(5)).map((point, index) => {
    //     console.log(index);
    //     <RatePoint active={index < currentRating} rating={index} setRating={setCurrentRating} key={index}/>
    // }))
  return (
    <div className='RatePlugin' style={{marginTop: marginTop}}>
        <span>{rateText}</span>
        <div className='RatePlugin_stars' style={{marginTop: '24.17px'}}>
            {
                Array.apply(null, Array(5)).map((point, index) => 
                    <RatePoint active={index < currentRating} rating={index + 1} setRating={setCurrentRating} key={index}/>
                )
            }
        </div>
        <div className='RatePlugin_buttons' style={{marginTop: '44.68px'}}>
            <div className='RatePlugin_buttons_rate' onClick={() => setRating(currentRating)}>Оценить</div>
            <div className='RatePlugin_buttons_cancel' onClick={cancelAction}>Отмена</div>
        </div>
    </div>
  )
}

export default RatePlugin