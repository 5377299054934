class Api {
    constructor(options) {
        this._address = options.baseUrl;
        this._headers = options.headers;
    }

    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        }
        return Promise.reject(`${res.status}`);
    }
    getAnswer(id) {
        return fetch(`${this._address}/${id}`, {
            method: 'GET',

        })
            .then(this._checkResponse)
    }

    createUser(data) {
        return fetch(`${this._address}`, {
            headers: this._headers,
            method: "POST",

            body: JSON.stringify({
              data: data
            }),
        }).then(this._checkResponse)
    }
}
const api = new Api({
    //localhost:1337
    baseUrl: '/backend/api/qas',
    headers: {
        'Content-Type': 'application/json',
    },
})
export default api;
